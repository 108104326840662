import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let BackupInfo = class BackupInfo extends Vue {
};
__decorate([
    Prop()
], BackupInfo.prototype, "description", void 0);
__decorate([
    Prop()
], BackupInfo.prototype, "size", void 0);
__decorate([
    Prop()
], BackupInfo.prototype, "mode", void 0);
__decorate([
    Prop()
], BackupInfo.prototype, "status", void 0);
__decorate([
    Prop()
], BackupInfo.prototype, "createdDate", void 0);
BackupInfo = __decorate([
    Component({})
], BackupInfo);
export default BackupInfo;
