import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
let BackupObjectsTable = class BackupObjectsTable extends Vue {
    constructor() {
        super(...arguments);
        this.fields = [
            { key: 'index', label: '#', thStyle: { width: '25px' } },
            { key: 'name', label: 'Name', thStyle: { width: '40%' } },
            { key: 'numberRecords', label: 'Records count', thStyle: { width: '75px' }, sortable: true },
            { key: 'queryWhereClause', label: 'Query where clause', thStyle: { width: '40%' } },
        ];
        this.pagination = {
            page: 1,
            total: 0,
            limit: 25,
        };
        this.search = '';
        this.tableSortDirection = null;
    }
    get objectsBySearch() {
        const regexp = new RegExp(this.search, 'i');
        return this.objects
            .filter(object => regexp.test(object.name))
            .map(object => ({ ...object, numberRecords: object.numberRecords || 0 }))
            .sort((a, b) => {
            if (this.tableSortDirection) {
                return this.tableSortDirection === 'asc'
                    ? a.numberRecords - b.numberRecords
                    : b.numberRecords - a.numberRecords;
            }
            return 0;
        });
    }
    get localOffset() {
        const { page, limit } = this.pagination;
        return (page - 1) * limit;
    }
    get shownObjects() {
        return this.objectsBySearch.slice(this.localOffset, this.localOffset + this.pagination.limit);
    }
    onSearchChanged() {
        this.pagination.total = this.objectsBySearch.length;
        this.pagination.page = 1;
    }
    onObjectClicked(item) {
        return item.name;
    }
    onSortChanged(ctx) {
        if (!ctx.sortBy) {
            this.tableSortDirection = null;
        }
        else {
            this.tableSortDirection = ctx.sortDesc ? 'desc' : 'asc';
        }
    }
};
__decorate([
    Prop()
], BackupObjectsTable.prototype, "objects", void 0);
__decorate([
    Prop()
], BackupObjectsTable.prototype, "loading", void 0);
__decorate([
    Watch('search'),
    Watch('objects', { deep: true })
], BackupObjectsTable.prototype, "onSearchChanged", null);
__decorate([
    Emit()
], BackupObjectsTable.prototype, "onObjectClicked", null);
BackupObjectsTable = __decorate([
    Component({})
], BackupObjectsTable);
export default BackupObjectsTable;
