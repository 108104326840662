import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import BackupObjectsTable from '@/components/backups/BackupObjectsTable.vue';
import BackupInfo from '@/components/backups/BackupInfo.vue';
import { RecordViewModuleStore } from '@/store/modules/record-view';
import BackupsApi from '@/services/api/backups.api';
import BackupUtils from '@/utils/backup.utils';
let BackupRecordViewBasePage = class BackupRecordViewBasePage extends Vue {
    constructor() {
        super(...arguments);
        this.isBackupObjectsLoading = false;
        this.backupObjects = [];
    }
    async goBack() {
        this.$router.push({
            name: 'backups.index',
        });
    }
    mounted() {
        this.fetchBackupObjects();
    }
    async openBackupObject(objectName) {
        try {
            const storage = await BackupUtils.getStorageForBackup(this.backup);
            if (!storage) {
                return;
            }
            this.$router
                .push({
                name: 'backups.backup.objects.object.records',
                params: {
                    objectName,
                },
                query: {
                    storageId: storage.id.toString(),
                },
            })
                .catch(() => false);
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    async fetchBackupObjects() {
        try {
            this.isBackupObjectsLoading = true;
            this.backupObjects = await BackupsApi.findBackupObjects(this.backup.id);
        }
        catch (error) {
            this.$toast.error(error);
        }
        finally {
            this.isBackupObjectsLoading = false;
        }
    }
};
__decorate([
    RecordViewModuleStore.State
], BackupRecordViewBasePage.prototype, "backup", void 0);
BackupRecordViewBasePage = __decorate([
    Component({
        components: {
            BackupObjectsTable,
            BackupInfo,
        },
    })
], BackupRecordViewBasePage);
export default BackupRecordViewBasePage;
